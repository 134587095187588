import * as routes from "../data/links/routes";
import { useAuth } from "../context/AuthContext";
import { Navigate, Outlet } from "react-router-dom";

const PrivateRoute = () => {
  let { hasUser } = useAuth();

  return hasUser ? <Outlet /> : <Navigate to={routes.LOGIN} />;
};

export default PrivateRoute;
