import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { StyledText } from "../data/style/style";

const DateHeader = () => {
  const [date, setDate] = useState(dayjs());

  useEffect(() => {
    const interval = setInterval(() => {
      setDate((prev) => prev.add(1, "second"));
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <StyledText dashboard="true">
      {date.format("hh:mm A") +
        " at " +
        date.format("D") +
        "th " +
        date.format("MMMM YYYY")}
    </StyledText>
  );
};

export default DateHeader;
