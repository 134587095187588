import "@fontsource/roboto";
import { Theme } from "./Theme";
import Box from "@mui/material/Box";
import styled from "@emotion/styled";
import Chip from "@mui/material/Chip";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Badge from "@mui/material/Badge";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import SvgIcon from "@mui/material/SvgIcon";
import MenuItem from "@mui/material/MenuItem";
import ListItem from "@mui/material/ListItem";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import TableCell from "@mui/material/TableCell";
import InputBase from "@mui/material/InputBase";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

export const FilterBox = styled(Box)({
  width: "15%",
  minWidth: "180px",
});

export const FormsButton = styled(Button)`
  background-color: ${Theme.palette.blue.dark};
  border-radius: 5px;
  height: 45px;
  font-family: ${Theme.typography.fontFamily};
  font-size: ${Theme.typography.fontSize.main};
  color: ${Theme.palette.white.main};
  text-transform: none;

  &:hover {
    background-color: ${Theme.palette.blue.light};
  }
`;

export const StyledButton = styled(Button)`
  background-color: ${Theme.palette.blue.dark};
  border-radius: 12px;
  min-width: 48px;
  padding: 7px;
  font-family: ${Theme.typography.fontFamily};
  font-size: 14px;
  color: ${Theme.palette.white.main};
  text-transform: none;
  width: 100px;

  &:hover {
    background-color: ${Theme.palette.blue.light};
  }
`;

export const AlternativeButton = styled(Button)`
  background-color: ${Theme.palette.blue.dark};
  border-radius: 6px;
  min-width: 55px;
  height: 35px;
  padding: 5px;
  font-family: ${Theme.typography.fontFamily};
  font-size: 14px;
  color: ${Theme.palette.white.main};
  text-transform: none;

  &:hover {
    background-color: ${Theme.palette.blue.light};
  }
`;

export const TableButton = styled(Button)((props) => ({
  background: props.disabled
    ? "#A2A2A2 !important"
    : `${Theme.palette.blue.dark}`,
  borderRadius: `6px`,
  height: `20px`,
  minWidth: "20px !important",
  width: "20px !important",
  fontSize: "12px",
  color: `${Theme.palette.white.main}`,
  "&:hover": {
    background: `${Theme.palette.blue.light}`,
  },
}));

export const ChatPanelButton = styled(Button)`
  background-color: ${Theme.palette.blue.dark};
  border-radius: 6px;
  height: 35px;
  min-width: 35px !important;
  width: 35px !important;
  font-size: 18px;
  color: white;
  &:hover {
    background-color: ${Theme.palette.blue.light};
  }
`;

export const SubTableButton = styled(Button)`
  background-color: white;
  border-radius: 6px;
  height: 20px;
  min-width: 20px !important;
  width: 20px !important;
  font-size: 12px;
  color: ${Theme.palette.blue.light};
  border: 1px solid ${Theme.palette.blue.light};
  &:hover {
    border: 1px solid ${Theme.palette.blue.dark};
    color: ${Theme.palette.blue.dark};
    background-color: white;
  }
`;

export const PaymentButton = styled(Button)`
  background-color: ${Theme.palette.green.light};
  border-radius: 6px;
  border: 2px solid white;
  text-transform: capitalize;
  font-size: 0.9rem;
  color: white;

  &:hover {
    background-color: white;
    border-color: ${Theme.palette.green.light};
    color: ${Theme.palette.green.light};
  }
`;

export const StyledContainer = styled(Container)`
  max-width: 350px !important;
  min-width: 270px !important;
  overflow: hidden;
`;

export const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "white",
    maxWidth: 220,
    textAlign: "justify",
    padding: "10px",
  },
}));

export const CustomChip = styled(Chip)`
  border-radius: 6px !important;
  font-family: ${Theme.typography.fontFamily};
  font-size: ${Theme.typography.fontSize.main};
  text-transform: capitalize;
  width: 95px;
  height: 27px;
  background-color: ${(props) =>
    props.status === 2
      ? `#1AD59866`
      : props.status === 0
      ? "#EA3A3D66"
      : "#f7f1af"};
  color: ${(props) =>
    props.status === 2
      ? "#17B481"
      : props.status === 0
      ? "#EA3A3D"
      : "#dca606"};
`;

export const RequestChip = styled(Chip)`
  border-radius: 5px !important;
  font-family: ${Theme.typography.fontFamily};
  font-size: ${Theme.typography.fontSize.main};
  text-transform: capitalize;
  height: 20px;
  background-color: ${(props) =>
    props.status === 2
      ? `#1AD59866`
      : props.status === 0
      ? "#EA3A3D66"
      : "#f7f1af"};
  color: ${(props) =>
    props.status === 2
      ? "#17B481"
      : props.status === 0
      ? "#EA3A3D"
      : "#dca606"};
`;

export const CustomTitle = styled(Typography)`
  color: #1a1a1a;
  font-family: ${Theme.typography.fontFamily};
  font-size: 24px;
  font-weight: bold;
`;

export const ResponseBox = styled(Stack)((props) => ({
  minHeight: "40px",
  minWidth: "250px",
  maxWidth: "500px",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "10px",
  padding: "10px",
  borderRadius: "18px",
  background: `${Theme.palette.white.main}`,
  border:
    props.status === 2
      ? `1.5px solid ${Theme.palette.blue.light}`
      : props.status === 3
      ? `1.5px solid ${Theme.palette.red.dark}`
      : `1.5px solid ${Theme.palette.grey.main}`,
}));

export const StyledLink = styled.a`
  color: ${Theme.palette.blue.light};
  font-family: ${Theme.typography.fontFamily};
  font-size: ${Theme.typography.fontSize.main};
  font-weight: ${Theme.typography.fontWeight.regular};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const ErrorPageLink = styled.a`
  color: ${Theme.palette.blue.light};
  font-family: ${Theme.typography.fontFamily};
  font-size: 1.3rem;
  font-weight: ${Theme.typography.fontWeight.regular};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const StatNumber = styled(Typography)`
  font-size: 40px;
  color: ${Theme.palette.grey.light};
  font-weight: bold;
`;

export const CounterText = styled(Typography)`
  line-height: 20px;
  font-family: ${Theme.typography.fontFamily};
  font-size: ${Theme.typography.fontSize.medium};
  font-weight: ${Theme.typography.fontWeight.bold};
  color: #ff6400;
`;

export const FeatureText = styled(Typography)((props) => ({
  fontFamily: `${Theme.typography.fontFamily}`,
  fontSize: "0.9rem",
  color: props.status === 0 ? `${Theme.palette.grey.light}` : "black",
}));

export const StyledText = styled(Typography)`
  color: ${(props) =>
    props.success
      ? `${Theme.palette.blue.light}`
      : props.fail === "true"
      ? `${Theme.palette.red.light}`
      : props.info
      ? "#626262"
      : props.req1
      ? "#124868"
      : props.card
      ? `${Theme.palette.grey.dark}`
      : `${Theme.palette.grey.main}`};
  font-family: ${Theme.typography.fontFamily};
  font-size: ${(props) =>
    props.dashboard || props.card
      ? `${Theme.typography.fontSize.medium}`
      : props.journey
      ? `${Theme.typography.fontSize.basic}`
      : `${Theme.typography.fontSize.main}`};
  font-weight: ${(props) =>
    (props.dashboard || props.card) &&
    `${Theme.typography.fontWeight.bold}`};
`;

export const SwitchText = styled(Typography)({
  fontSize: `${Theme.typography.fontSize.medium}`,
  fontWeight: "bold",
  color: `${Theme.palette.grey.main}`,
  fontFamily: `${Theme.typography.fontFamily}`,
});

export const ParameterText = styled(Typography)((props) => ({
  fontSize: `${Theme.typography.fontSize.main}`,
  color: `${Theme.palette.blue.light}`,
  fontFamily: `${Theme.typography.fontFamily}`,
  fontWeight: props.bold
    ? `${Theme.typography.fontWeight.bold}`
    : `${Theme.typography.fontWeight.regular}`,
}));

export const TypeText = styled(Typography)({
  fontSize: `${Theme.typography.fontSize.main}`,
  color: "#124868",
  fontFamily: `${Theme.typography.fontFamily}`,
});

export const ColoredScore = styled(Typography)`
  font-family: ${Theme.typography.fontFamily};
  font-weight: ${(props) =>
    props.bold && Theme.typography.fontWeight.bold};
  font-size: 0.9rem;
  color: ${(props) =>
    props.ispercentage === "true"
      ? props.csat <= 50
        ? "#EA3A3D !important"
        : props.csat <= 70
        ? "#F77807 !important"
        : props.csat <= 80
        ? "#dca606 !important"
        : props.csat <= 90
        ? "#1AD396 !important"
        : "#0DAF02 !important"
      : props.csat <= 1
      ? "#EA3A3D !important"
      : props.csat <= 2
      ? "#F77807 !important"
      : props.csat <= 3
      ? "#dca606 !important"
      : props.csat <= 4
      ? "#1AD396 !important"
      : "#0DAF02 !important"};
`;

export const StyledTableCell = styled(TableCell)((props) => ({
  fontFamily: `${Theme.typography.fontFamily}`,
  padding: "5px",
  minWidth:
    props.small === "true"
      ? "60px"
      : props.medium === "true"
      ? "105px"
      : props.date === "true"
      ? "120px"
      : props.big === "true"
      ? "200px"
      : props.huge === "true"
      ? "300px"
      : "80px",
  wordBreak: "break-word",
  fontSize: props.header
    ? `${Theme.typography.fontSize.main}`
    : "12px",
  fontWeight: props.header
    ? `${Theme.typography.fontWeight.bold}`
    : `${Theme.typography.fontWeight.regular}`,
  borderBottom: "1px solid",
  borderColor: `${Theme.palette.grey.main}`,
}));

export const StyledCard = styled(Card)`
  border-radius: 16px;
`;

export const CssTextField = styled(TextField)({
  width: "100%",
  fontSize: `${Theme.typography.fontSize.basic}`,
  color: `${Theme.palette.grey.main}`,

  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "1px solid",
      borderColor: `${Theme.palette.grey.light}`,
    },
    "&:hover fieldset": {
      borderColor: `${Theme.palette.blue.light}`,
    },
    "&.Mui-focused fieldset": {
      border: "1px solid",
      borderColor: `${Theme.palette.blue.light}`,
    },
  },
});

export const InCssField = styled(TextField)({
  fontSize: "14px",
  "& input": {
    color: `${Theme.palette.grey.main}`,
  },

  "& label.Mui-focused": {
    color: `${Theme.palette.blue.light}`,
  },
  "& label.Mui-hover": {
    color: `${Theme.palette.blue.light}`,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "1px solid",
      height: "42px",
      borderColor: `${Theme.palette.grey.light}`,
    },
    "&:hover fieldset": {
      border: "1px solid",
      borderColor: `${Theme.palette.blue.light}`,
    },
    "&.Mui-focused fieldset": {
      border: "1px solid",
      borderColor: `${Theme.palette.blue.light}`,
    },
  },
});

export const PrimarySwitch = styled((props) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    inputRef={props.re}
    checked={props.value}
    {...props}
  />
))(({ theme }) => ({
  width: 39,
  height: 23.6,
  padding: 0,
  borderRadius: 13,
  border: "2px solid",
  borderColor: theme.palette.white.main,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.blue.light,
        opacity: 1,
        border: 0,
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 16,
    height: 16,
  },
  "& .MuiSwitch-track": {
    borderRadius: 13,
    backgroundColor: "#F0F0F0",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 300,
    }),
  },
}));

export const CustomInput = styled(InputBase)({
  "& .MuiInputBase-input": {
    borderRadius: "4px",
    border: "1px solid",
    borderColor: `${Theme.palette.grey.light}`,
    fontSize: `${Theme.typography.fontSize.main}`,
    fontFamily: `${Theme.typography.fontFamily}`,
    color: `${Theme.palette.grey.main}`,
    padding: "6px",
    width: "70%",

    "&:hover": {
      borderColor: `${Theme.palette.blue.light}`,
    },
    "&:focus": {
      borderColor: `${Theme.palette.blue.light}`,
    },
  },
});

export const StyledHeader = styled.p`
  color: ${Theme.palette.grey.dark};
  font-family: ${Theme.typography.fontFamily};
  font-size: ${Theme.typography.fontSize.large};
`;

export const MenuText = styled(Typography)`
  font-family: ${Theme.typography.fontFamily};
  font-size: ${Theme.typography.fontSize.medium};
  font-weight: ${Theme.typography.fontWeight.bold};
`;

export const StyledListItem = styled(ListItem)`
  color: ${Theme.palette.grey.main};
  width: 96%;
  margin-left: 2%;
  text-decoration: none;
  border-radius: 20px;

  &:hover {
    background-color: ${Theme.palette.blue.light};
    color: ${Theme.palette.white.main};
    border-radius: 20px;
  }
`;

export const StyledSubListItem = styled(ListItem)`
  color: ${Theme.palette.blue.light};
  width: 96%;
  margin-left: 2%;

  &:hover {
    background-color: ${Theme.palette.blue.light};
    color: ${Theme.palette.white.main};
    border-radius: 20px;
  }
`;

export const StyledBadge = styled(Badge)((props) => ({
  "& .MuiBadge-badge": {
    backgroundColor:
      props.status === 2
        ? "#17B481"
        : props.status === 1
        ? "#dca606"
        : "#EA3A3D",
    border: "2px solid white",
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

export const ProgressBar = styled(LinearProgress)(({ theme }) => ({
  height: 7,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.blue.light,
  },
}));

export const ModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 430,
  background: Theme.palette.white.main,
  p: 1,
  borderRadius: "14px",
  outline: "none",
};

export const scrollBar = {
  "::-webkit-scrollbar": {
    width: "5px",
    height: "5px",
    backgroundColor: "rgba(162,162,162, 0.15)",
    borderRadius: "14px",
  },
  "::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(48,152,213, 0.25)",
    borderRadius: "14px",
  },
  "::-webkit-scrollbar-track-piece:start": {
    background: "transparent",
  },
  "::-webkit-scrollbar-track-piece:end": {
    background: "transparent",
  },

  ":hover": {
    "::-webkit-scrollbar": {
      backgroundColor: "rgba(162,162,162, 0.5)",
    },
    "::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(48,152,213, 0.8)",
    },
  },
};

export const bigTableScrollBar = {
  // height: "65vh",
  "::-webkit-scrollbar": {
    width: "5px",
    height: "5px",
    backgroundColor: "rgba(162,162,162, 0.15)",
    borderRadius: "14px",
  },
  "::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(48,152,213, 0.25)",
    borderRadius: "14px",
  },
  "::-webkit-scrollbar-track-piece:start": {
    background: "transparent",
  },
  "::-webkit-scrollbar-track-piece:end": {
    background: "transparent",
  },

  ":hover": {
    "::-webkit-scrollbar": {
      backgroundColor: "rgba(162,162,162, 0.5)",
    },
    "::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(48,152,213, 0.8)",
    },
  },
};

export const smallTableScrollBar = {
  height: "16vh",
  minHeight: "140px",
  "::-webkit-scrollbar": {
    width: "5px",
    height: "5px",
    backgroundColor: "rgba(162,162,162, 0.15)",
    borderRadius: "14px",
  },
  "::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(48,152,213, 0.25)",
    borderRadius: "14px",
  },
  "::-webkit-scrollbar-track-piece:start": {
    background: "transparent",
  },
  "::-webkit-scrollbar-track-piece:end": {
    background: "transparent",
  },

  ":hover": {
    "::-webkit-scrollbar": {
      backgroundColor: "rgba(162,162,162, 0.5)",
    },
    "::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(48,152,213, 0.8)",
    },
  },
};

export const LegendText = styled(Badge)((props) => ({
  fontFamily: `${Theme.typography.fontFamily}`,
  fontWeight: `${Theme.typography.fontWeight.bold}`,
  fontSize: props.fontSize,
}));

export const TutorialStack = styled(Stack)({
  overflow: "hidden",
  height: "100vh",
  background: "#F0F0F0",
  justifyContent: "space-between",
  alignItems: "center",
  minWidth: "630px",
});

export const CustomMenuItem = styled(MenuItem)({
  fontSize: "14px",
  color: `${Theme.palette.grey.main}`,
  "&:hover": {
    color: `${Theme.palette.white.main}`,
    background: `${Theme.palette.blue.light}`,
  },
});

export const CustomFormControlLabel = styled(FormControlLabel)({
  fontSize: "14px",
  fontFamily: `${Theme.typography.fontFamily}`,
  color: `${Theme.palette.grey.main}`,
});

export const AnnualLabelDiv = styled(Stack)`
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  border: 2px solid ${Theme.palette.blue.light};
  top: -3px;
  left: 0px;
  height: 30px;
  background: ${Theme.palette.blue.light};
  color: white;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`;

export const StyledPlanCard = styled(Card)((props) => ({
  padding: "10px",
  borderRadius: "16px",
  border: "1px solid",
  borderColor:
    props.title === "Pro Plus"
      ? `${Theme.palette.blue.light}`
      : `${Theme.palette.grey.light}`,
  width: "92%",
  position: "relative",
  color: "#626262",
  height: "100%",
}));

export const ChatArea = styled(Box)`
  width: 100%;
  height: 78vh;
  display: flex;
  flex-shrink: 1;
`;

export const Menu = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: 240,
  height: "100vh",
  borderRadius: 0,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
  }),
}));

const openedMixin = (theme) => ({
  width: 240,
  transition: theme.transitions.create("width", {
    duration: "500ms",
  }),
  overflowX: "hidden",
  overflowY: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    duration: "500ms",
  }),
  overflowX: "hidden",
  overflowY: "hidden",
  width: `calc(${theme.spacing(5)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(6)} + 1px)`,
  },
});

export const AutoCompleteLabel = styled(Typography)({
  fontFamily: `${Theme.typography.fontFamily}`,
  fontWeight: `${Theme.typography.fontWeight.bold}`,
  fontSize: `${Theme.typography.fontSize.main}`,
  color: `${Theme.palette.blue.light}`,
});

export const ExportButton = styled(Button)({
  background: `${Theme.palette.blue.light}`,
  borderRadius: `12px`,
  minWidth: `48px`,
  padding: `7px`,
  border: "1px solid",
  fontFamily: `${Theme.typography.fontFamily}`,
  borderColor: `${Theme.palette.blue.light}`,
  fontSize: `${Theme.typography.fontSize.main}`,
  color: `${Theme.palette.white.main}`,
  textTransform: `none`,

  "&:hover": {
    background: `${Theme.palette.white.main}`,
    color: `${Theme.palette.blue.light}`,
  },
});

export const ChannelDiv = styled(Box)({
  width: "15%",
  borderRadius: "16px",
  cursor: "pointer",
  minWidth: "150px",
  transition: "all .2s ease-in-out",
  "&: hover": {
    transform: "scale(1.2)",
  },
});

export const CircleBadge = styled(Stack)({
  width: "140px",
  height: "140px",
  borderRadius: "50%",
  border: "10px solid #124868",
  alignItems: "center",
  justifyContent: "center",
});

export const QuestionStack = styled(Stack)({
  paddingLeft: 1,
  paddingRight: 1,
  width: "65%",
  minWidth: "365px",
  maxWidth: "640px",
  minHeight: "100vh",
  justifyContent: "space-between",
  background: `${Theme.palette.white.main}`,
  gap: 2,
});

export const CounterDiv = styled(Stack)((props) => ({
  width: "25px",
  height: "25px",
  background:
    props.back === "true"
      ? `${Theme.palette.white.main}`
      : `${Theme.palette.blue.light}`,
  borderRadius: "50%",
  alignItems: "center",
  justifyContent: "center",
}));

export const VerticalCenteredRow = styled(Stack)({
  flexDirection: "row",
  alignItems: "center",
});

export const StyledSVGIcon = styled(SvgIcon)({
  fontSize: "20px",
  color: "#124868",
});

export const ProfileModalIcon = styled(SvgIcon)({
  fontSize: "1.5rem",
  color: `${Theme.palette.grey.main}`,
});

export const QuestionText = styled(Typography)`
  font-family: ${Theme.typography.fontFamily};
  color: black;
  font-size: ${Theme.typography.fontSize.medium};
`;

export const QuestionSubText = styled(Typography)`
  font-family: ${Theme.typography.fontFamily};
  color: ${Theme.palette.grey.light};
  font-size: ${Theme.typography.fontSize.main};
`;

export const ErrorPageText = styled(Typography)({
  display: "block !important",
  fontSize: "1.3rem",
  color: `${Theme.palette.grey.main}`,
  fontFamily: "Lato",
});

export const BigModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  minWidth: 600,
  background: `${Theme.palette.white.main}`,
  p: 1,
  borderRadius: "14px",
  outline: "none",
};

export const SpacedRow = styled(Stack)({
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
});

export const StatsBox = styled(Stack)({
  width: "15%",
  border: "1px solid",
  borderColor: `${Theme.palette.grey.light}`,
  borderRadius: "16px",
  minWidth: "170px",
});

export const NewMessageDiv = styled(Stack)({
  alignItems: "center",
  borderRadius: "5px",
  background: `${Theme.palette.blue.dark}`,
  borderColor: `${Theme.palette.blue.dark}`,
});

export const NewMessageHeader = styled(Typography)({
  fontSize: "12px !important",
  color: `${Theme.palette.white.main}`,
  fontWeight: `${Theme.typography.fontWeightBold}`,
  fontFamily: `${Theme.typography.fontFamily}`,
});

export const ChatBox = styled(Stack)((props) => ({
  paddingLeft: "5px",
  minHeight: "510px",
  paddingRight: "5px",
  borderRight: props.hasticket && "1px solid",
  borderLeft: "1px solid",
  borderColor: `${Theme.palette.grey.light}`,
  width: "100%",
  height: props.height > 900 ? "84vh" : "80vh",
}));

export const CodeBox = styled(Box)({
  background: "#F7F7F7",
  padding: "5px",
  borderRadius: "10px",
  minWidth: "330px",
});

export const CustomTab = styled(Box)((props) => ({
  borderBottom:
    props.active && `2px solid ${Theme.palette.blue.light}`,
  padding: "7px",
  cursor: "pointer",

  "&:hover": {
    background: "#F7F7F7",
  },
}));

export const ModalSecondaryForm = styled(Stack)({
  paddingTop: "10px",
  paddingBottom: "10px",
  borderRadius: "10px",
  flexDirection: "row",
  border: "1px solid",
  borderColor: `${Theme.palette.grey.light}`,
});

export const MessageDiv = styled(Stack)((props) => ({
  width: "46%",
  padding: "10px",
  maxWidth: "300px",
  borderRadius: "18px",
  border:
    props.fromtype === 0
      ? `1px solid ${Theme.palette.messages.agent.background}`
      : `1px solid  ${Theme.palette.messages.contact.background}`,
  background:
    props.fromtype === 0
      ? `${Theme.palette.messages.agent.background}`
      : `${Theme.palette.messages.contact.background}`,
}));

export const MessageIcon = styled(SvgIcon)((props) => ({
  color:
    props.fromtype === 1
      ? `${Theme.palette.blue.dark}`
      : `${Theme.palette.messages.agent.text}`,
  fontSize: "40px",
  cursor: "pointer",
}));

export const MessageText = styled(Typography)((props) => ({
  fontFamily: `${Theme.typography.fontFamily}`,
  fontSize: `${Theme.typography.fontSize.main}`,
  cursor: props.cursor === "true" && "pointer",
  whiteSpace: "preserve-breaks",
  color:
    props.fromtype === 1
      ? `${Theme.palette.blue.dark}`
      : `${Theme.palette.messages.agent.text}`,
}));

export const AttributeRow = styled(Stack)((props) => ({
  flexDirection: "row",
  padding: "5px",
  opacity: props.deleted === 1 && "50%",
  borderBottom: "1px solid",
  borderColor: `${Theme.palette.grey.light}`,
  borderLeft:
    props.deleted === 1
      ? "4px solid red"
      : props.added === 1
      ? "4px solid green"
      : "",
}));

export const TicketText = styled(Typography)((props) => ({
  fontFmily: `${Theme.typography.fontFamily}`,
  fontSize:
    props.big === "true"
      ? `${Theme.typography.fontSize.medium}`
      : `${Theme.typography.fontSize.main}`,
  fontWeight:
    props.bold === "true" && `${Theme.typography.fontWeight.bold}`,
  color:
    props.active === "true"
      ? `${Theme.palette.white.main}`
      : props.count !== 0
      ? `${Theme.palette.blue.light}`
      : props.big === "true" || props.bold === "true"
      ? `${Theme.palette.grey.dark}`
      : `${Theme.palette.grey.main}`,
}));

export const TicketTextSm = styled(Typography)((props) => ({
  fontFmily: `${Theme.typography.fontFamily}`,
  fontSize: `${Theme.typography.fontSize.basic}`,
  fontWeight:
    props.bold === "true" && `${Theme.typography.fontWeight.bold}`,
  color:
    props.active === "true"
      ? `${Theme.palette.white.main}`
      : props.count !== 0
      ? `${Theme.palette.blue.light}`
      : props.big === "true" || props.bold === "true"
      ? `${Theme.palette.grey.dark}`
      : `${Theme.palette.grey.main}`,
}));

export const TicketCard = styled(Box)((props) => ({
  minWidth: "180px",
  cursor: "pointer",
  borderRadius: "6px",
  padding: "5px",
  background:
    props.active === "true"
      ? `${Theme.palette.blue.light}`
      : `${Theme.palette.white.main}`,
}));

export const CenteredColumn = styled(Stack)(() => ({
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
}));

export const CenteredRow = styled(Stack)(() => ({
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
}));

export const QuestionScore = styled(Stack)((props) => ({
  background:
    props.pressed === "true"
      ? `${Theme.palette.blue.light}`
      : "#E9E9E9",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minWidth: "32px",
  minHeight: "32px",
  borderRadius: "5px",
  color:
    props.pressed === "true"
      ? `${Theme.palette.white.main}`
      : `${Theme.palette.grey.light}`,
  cursor: "pointer",

  "&:hover": {
    background: `${Theme.palette.blue.light}`,
    color: `${Theme.palette.white.main}`,
  },
}));

export const IconDiv = styled(Stack)((props) => ({
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  background: props.selected && `${Theme.palette.white.main}`,
}));

export const LinearLoader = styled(LinearProgress)({
  height: "15px",
  background: "white",
  color: `${Theme.palette.blue.light} !important`,
  borderRadius: "16px",
  width: "100%",
});

export const PreparedReplyBox = styled(Box)({
  background: `${Theme.palette.white.main}`,
  fontFamily: `${Theme.typography.fontFamily}`,
  border: "1px solid",
  borderColor: `${Theme.palette.grey.light}`,
  borderRadius: "8px",
  color: `${Theme.palette.grey.light}`,
  cursor: "pointer",
  padding: "8px",
  fontSize: "14px",
  "&: hover": {
    border: "1px solid",
    color: `${Theme.palette.white.main}`,
    background: `${Theme.palette.blue.light}`,
    borderColor: `${Theme.palette.blue.light}`,
  },
});

export const PlanBadge = styled(Stack)((props) => ({
  justifyContent: "center",
  position: "absolute",
  borderTopLeftRadius: "16px",
  borderTopRightRadius: "16px",
  border: "1px solid",
  borderColor: `${Theme.palette.blue.light}`,
  textAlign: "center",
  width: "85%",
  top: "-30px",
  color:
    props.billing === 0 ? "#FFFFFF" : `${Theme.palette.blue.light}`,
  height: "30px",
  background:
    props.billing === 0 ? `${Theme.palette.blue.light}` : "#FFFFFF",
}));

export const DefaultContactBadge = styled(Stack)({
  background: `${Theme.palette.blue.light}`,
  padding: "5px",
  fontSize: "0.625rem",
  color: `${Theme.palette.white.main}`,
  height: "0.75rem",
  borderRadius: "10px",
});

export const PlanBadgeBox = styled(Box)({
  height: "20px",
  padding: "5px",
  border: "1px solid #FFFFFF",
  background: "#2daf64",
  borderRadius: "10px",
  textAlign: "center",
});

export const HeaderIcon = {
  fontSize: "2rem",
  color: `${Theme.palette.blue.dark}`,
  cursor: "pointer",
  transition: "500ms",
  "&:hover": {
    transform: "scale(1.2)",
  },
};

export const ProfileModalRow = styled(Stack)((props) => ({
  marginBottom: "10px",
  flexDirection: "row",
  cursor: "pointer",
  transition: "all .2s ease-in-out",
  "&: hover": {
    transform: props.index !== 1 && "scale(1.1)",
  },
}));

export const ChartTooltip = styled(Stack)({
  borderRadius: "5px",
  background: "white",
  border: "1px solid #A2A2A2",
  padding: "10px",
});

export const QueueDiv = styled(Stack)({
  height: "7vh",
  flexWrap: "wrap",
  overflow: "auto",
  minHeight: "40px",
});
