import "@fontsource/lato";
import "@fontsource/roboto";
import { createTheme } from "@mui/material/styles";

export const Theme = createTheme({
  palette: {
    default: {
      main: "#626262",
    },
    error: {
      main: "#af3a2d",
    },
    success: {
      main: "#2daf64",
    },
    secondary: {
      main: "#3098D5",
    },
    blue: {
      dark: "#124868",
      light: "#3098D5",
    },
    infoBanner: {
      font: "#dca606",
      background: "#f7f1af",
    },
    channels: {
      facebook: "#4267B2",
      instagram: "#E106A2",
      whatsApp: "#25D366",
    },
    messages: {
      agent: {
        text: "#1a5000",
        background: "#B5FA94",
      },
      contact: {
        background: "#63c3fc",
      },
    },
    grey: {
      dark: "#1A1A1A",
      light: "#A2A2A2",
      lighter: "#D6DBDF",
      main: "#626262",
      secondary: "#B8B8B8",
      background: "#F0F0F0",
    },
    red: {
      lighter: "#FC2200",
      light: "#af3a2d",
      dark: "#cc0000",
      secondary: "#EA3A3D",
    },
    green: {
      lighter: "#00FC6B",
      light: "#2daf64",
      secondary: "#9BFEF1",
      main: "#0DD300",
    },
    white: {
      dark: "#FDFDFD",
      main: "#FFFFFF",
    },
    yellow: {
      light: "#f7f1af",
      main: "#f7f1af",
      dark: "#dca606",
    },
    orange: {
      light: "#FCD074",
    },
  },
  typography: {
    fontFamily: "Lato , Roboto",
    fontSize: {
      main: "0.875rem",
      medium: "1rem",
      large: "1.625rem",
      basic: "0.75rem",
    },
    fontWeight: {
      regular: 400,
      bold: 700,
    },
  },
  spacing: 10,
});
