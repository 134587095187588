import * as routes from "../data/links/routes";
import { useAuth } from "../context/AuthContext";
import { useResolvedPath, Navigate, Outlet } from "react-router-dom";

const admin_forbidden_routes = ["/plans", "/transactions"];

const AdminRoute = () => {
  let { user } = useAuth();
  const { pathname } = useResolvedPath();
  return user.level === 1 &&
    admin_forbidden_routes.indexOf(pathname) !== -1 ? (
    <Navigate to={routes.DASHBOARD} />
  ) : (
    <Outlet />
  );
};

export default AdminRoute;
