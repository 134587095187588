const BACKEND_URL = process.env.REACT_APP_DOMAIN;
export const CAPTCHA = BACKEND_URL + "captcha";
export const LOGIN = BACKEND_URL + "login";
export const LOGOUT = BACKEND_URL + "logout";
export const REGISTER = BACKEND_URL + "register";
export const REFRESH = BACKEND_URL + "refresh";
export const RESET_PASSWORD = BACKEND_URL + "resetpassword";
export const VERIFY_EMAIL = BACKEND_URL + "verifyuser";
export const CONFIRM_EMAIL = BACKEND_URL + "mailconfirmation/";
export const COMPLETE_REGISTER = BACKEND_URL + "profile";
export const COMPLETE_PROFILE = BACKEND_URL + "userdata";
export const CREATE_USER = BACKEND_URL + "createuser";
export const GET_ORGANIZATION = BACKEND_URL + "organizations";
export const UPDATE_ORGANIZATION =
  BACKEND_URL + "organizations/update";
export const GET_ORGANIZATION_IMAGE =
  BACKEND_URL + "organizations/image";
export const GET_ORG_SETTINGS =
  BACKEND_URL + "organizations/settings";
export const UPDATE_SETTINGS =
  BACKEND_URL + "organizations/settings/update";
export const GET_USER_BY_ID = BACKEND_URL + "users/";
export const SEARCH_USERS = BACKEND_URL + "users/search";
export const SEARCH_USERS_WITH_PARAM =
  BACKEND_URL + "users/search/oneparam";
export const USER_STATS = BACKEND_URL + "users/count";
export const UPDATE_USER_PROFILE = BACKEND_URL + "users/update/";
export const UPDATE_ONLINE_STATUS =
  BACKEND_URL + "users/update/onlinestatus";
export const UPDATE_PROFILE = BACKEND_URL + "users/updateself";
export const UPDATE_USER_IMAGE =
  BACKEND_URL + "users/update/self/image";
export const UPDATE_USER_BROWSER_NOTIFICATIONS =
  BACKEND_URL + "users/update/notifications/browser";
export const UPDATE_USER_EMAIL_NOTIFICATIONS =
  BACKEND_URL + "users/update/notifications/email";
export const DELETE_USER = BACKEND_URL + "users/delete/";
export const GET_LANGUAGES = BACKEND_URL + "languages";
export const GET_ORG_SKILLS = BACKEND_URL + "skills/";
export const UPDATE_SKILL = BACKEND_URL + "skills/update";
export const INSERT_SKILL = BACKEND_URL + "skills/store";
export const DELETE_SKILL = BACKEND_URL + "skills/delete/";
export const SEARCH_SKILLS = BACKEND_URL + "skills/search";
export const SKILLS_STATS = BACKEND_URL + "skills/count";
export const GET_SKILL_CATEGORIES = BACKEND_URL + "skillcategories";
export const INSERT_SKILL_CATEGORY =
  BACKEND_URL + "skillcategories/store/";
export const DELETE_SKILL_CATEGORY =
  BACKEND_URL + "skillcategories/delete/";
export const INSERT_CONTACT = BACKEND_URL + "contacts/store";
export const REMOVE_CONTACT = BACKEND_URL + "contacts/delete/";
export const UPDATE_CONTACT = BACKEND_URL + "contacts/update/";
export const MERGE_CONTACTS = BACKEND_URL + "contacts/merge/";
export const SEARCH_CONTACTS = BACKEND_URL + "contacts/search/";
export const SEARCH_CONTACTS_WITH_PARAM =
  BACKEND_URL + "contacts/merge/search";
export const CONTACT_STATS = BACKEND_URL + "contacts/count";
export const GET_CONTACT_EVENTS =
  BACKEND_URL + "contacts/events/search";
export const UPLOAD_CONTACT_CSV = BACKEND_URL + "contacts/store/csv";
export const DOWNLOAD_EXAMPLE_CSV = BACKEND_URL + "contacts/template";
export const INSERT_CONTACT_EVENTS =
  BACKEND_URL + "contacts/events/store";
export const ASSIGN_IDENTIFIER =
  BACKEND_URL + "contacts/identifiers/assign";
export const GET_TIMEZONES = BACKEND_URL + "timezones";
export const GET_BUSINESS_HOURS = BACKEND_URL + "bhours";
export const INSERT_BUSINESS_HOURS = BACKEND_URL + "bhours/store";
export const UPDATE_BUSINESS_HOURS =
  BACKEND_URL + "bhours/update/all";
export const DELETE_ALL_BUSINESS_HOURS =
  BACKEND_URL + "bhours/nohours";
export const GET_DEFAULT_BUSINESS_HOURS =
  BACKEND_URL + "bhours/default";
export const INSERT_REPLY_CATEGORY =
  BACKEND_URL + "replycategories/store";
export const GET_REPLY_CATEGORIES = BACKEND_URL + "replycategories";
export const DELETE_REPLY_CATEGORY =
  BACKEND_URL + "replycategories/delete/";
export const GET_CHANNELS = BACKEND_URL + "channels";
export const GET_ORG_CHANNEL_ACCOUNTS =
  BACKEND_URL + "channelaccounts";
export const UPDATE_CHANNEL_ACCOUNT =
  BACKEND_URL + "channelaccounts/update";
export const GET_CHANNEL_ACCOUNT_QUEUES =
  BACKEND_URL + "channelaccounts/queues/";
export const GET_PREPARED_REPLIES =
  BACKEND_URL + "preparedreplies/search";
export const INSERT_PREPARED_REPLY =
  BACKEND_URL + "preparedreplies/store";
export const UPDATE_PREPARED_REPLY =
  BACKEND_URL + "preparedreplies/update";
export const DELETE_PREPARED_REPLY =
  BACKEND_URL + "preparedreplies/delete/";
export const SEARCH_REPLIES = BACKEND_URL + "preparedreplies/search/";
export const GET_QUEUES = BACKEND_URL + "queues";
export const GET_ENABLED_QUEUES = BACKEND_URL + "queues/transfer";
export const GET_NO_SLA_QUEUES = BACKEND_URL + "queues/noslas";
export const INSERT_QUEUE = BACKEND_URL + "queues/store";
export const UPDATE_QUEUE = BACKEND_URL + "queues/update";
export const DELETE_QUEUE = BACKEND_URL + "queues/delete/";
export const QUEUE_STATS = BACKEND_URL + "queues/count";
export const ADD_USER_TO_QUEUE = BACKEND_URL + "queueusers/store";
export const TICKETS_SEARCH = BACKEND_URL + "tickets/search";
export const GET_TICKETS_BY_USER = BACKEND_URL + "tickets/user/self";
export const GET_NEW_TICKETS = BACKEND_URL + "tickets/pull/";
export const MOVE_TICKET = BACKEND_URL + "tickets/move/";
export const GET_SLAS = BACKEND_URL + "slas";
export const DELETE_SLA = BACKEND_URL + "slas/delete/";
export const CREATE_OR_UPDATE_SLA = BACKEND_URL + "slas/update";
export const GET_CONTACT_BY_ID = BACKEND_URL + "contacts/";
export const GET_NEW_MESSAGES = BACKEND_URL + "messages/pull/";
export const GET_TICKET_MESSAGES = BACKEND_URL + "messages/";
export const CLOSE_TICKET = BACKEND_URL + "tickets/close/";
export const GET_TICKET_BY_REF_NUMBER = BACKEND_URL + "tickets/get/";
export const GET_BILLING_INFO = BACKEND_URL + "billinginfo";
export const UPDATE_BILLING_INFO = BACKEND_URL + "billinginfo/update";
export const GET_COUNTRIES = BACKEND_URL + "countries";
export const CONNECT_ACCOUNT =
  BACKEND_URL + "channelaccounts/auth/callback";
export const CHANGE_CHANNEL_ACCOUNT_STATUS =
  BACKEND_URL + "channelaccounts/update/status";
export const SEND_MESSAGE = BACKEND_URL + "messages/store";
export const DONWLOAD_MESSAGE_FILE =
  BACKEND_URL + "messages/download/";
export const GET_LABELS = BACKEND_URL + "contactlabels";
export const INSERT_LABEL = BACKEND_URL + "contactlabels/store";
export const DELETE_LABEL = BACKEND_URL + "contactlabels/delete/";
export const SAVE_CONTACT_VALUES =
  BACKEND_URL + "contactvalues/store";
export const GET_AUTO_REPLY_BY_TYPE =
  BACKEND_URL + "automatedreplies/";
export const UPDATE_AUTO_REPLY =
  BACKEND_URL + "automatedreplies/update";
export const GET_PLAN = BACKEND_URL + "activeservices/currentplan";
export const SEARCH_TRANSACTIONS = BACKEND_URL + "invoices/search";
export const GET_REPORTS = BACKEND_URL + "reports";
export const EXPORT_REPORT = BACKEND_URL + "reports/store";
export const GET_METRICS = BACKEND_URL + "customermetrics";
export const UPDATE_METRIC_STATUS =
  BACKEND_URL + "customermetrics/status/";
export const UPDATE_METRIC_QUESTION =
  BACKEND_URL + "customermetrics/update";
export const GET_ORG_QUESTIONS = BACKEND_URL + "cex/";
export const SEND_CEX_SCORES = BACKEND_URL + "cex/update";
export const CEX_SUBMIT = BACKEND_URL + "cex/finish";
export const CEX_ORG_IMAGE = BACKEND_URL + "cex/image/";
export const GET_CHART_DATA = BACKEND_URL + "charts/";
export const GET_SURVEYS = BACKEND_URL + "charts/search/surveys";
export const GET_RESPONSES = BACKEND_URL + "charts/search/responses";
export const GET_NOTIFICATIONS = BACKEND_URL + "notifications";
export const READ_NOTIFICATION = BACKEND_URL + "notifications/read/";
export const READ_ALL_NOTIFICATIONS =
  BACKEND_URL + "notifications/all/read/";
export const GET_ACTIVE_SERVICES = BACKEND_URL + "activeservices";
export const UPDATE_ACTIVE_SERVICE =
  BACKEND_URL + "activeservices/update";
export const GET_PAYMENT_SESSION = BACKEND_URL + "payments/session";
export const GET_PLANS = BACKEND_URL + "pricing/plans";
export const CALCULATE_PACKAGE_DIFFERENCE =
  BACKEND_URL + "activeservices/changeplan/";
export const CHANGE_PLAN = BACKEND_URL + "activeservices/session/";
export const CONFIRM_DOWNGRADE_PLAN =
  BACKEND_URL + "activeservices/changeplan/success";
export const GET_SURVEY_STATS = BACKEND_URL + "charts/metric/";
export const GET_PLAYLIST = BACKEND_URL + "planchange";
export const CANCEL_PLAN_CHANGE = BACKEND_URL + "planchange/cancel";
export const GET_USERS_ACTIVE_TICKET_COUNT =
  BACKEND_URL + "tickets/user/count";
export const UPDATE_SET_UP = BACKEND_URL + "users/update/setup";
export const GET_TICKET_STATUS_COUNTS =
  BACKEND_URL + "dashboard/tickets/status";
export const GET_TICKET_SCORES =
  BACKEND_URL + "dashboard/tickets/scores";
export const GET_USER_PERFORMANCE =
  BACKEND_URL + "statistics/user/performance";
export const GET_USER_AVAILABILITY =
  BACKEND_URL + "dashboard/users/availability";
export const GET_TOP_USERS = BACKEND_URL + "dashboard/users/top";
export const GET_TOP_CUSTOMERS =
  BACKEND_URL + "dashboard/customers/top";
export const GET_CUSTOMERS_FEEDBACK =
  BACKEND_URL + "dashboard/customers/feedback";
export const GET_CUSTOMERS_EXPERIENCE =
  BACKEND_URL + "dashboard/customers/experience";
export const GET_TOP_CHANNELS =
  BACKEND_URL + "dashboard/channels/top";
export const GET_TRENDING_SKILLS =
  BACKEND_URL + "statistics/skills/trending";
export const GET_CHANNEL_CHART_DATA =
  BACKEND_URL + "dashboard/channels/chart";
export const GET_QUEUE_TABLE_DATA = BACKEND_URL + "statistics/queues";
export const GET_REPORT_TYPES = BACKEND_URL + "reports/types";
export const GET_REPORT_SUBTYPES = BACKEND_URL + "reports/subtypes/";
export const GET_USER_COUNTS = BACKEND_URL + "statistics/entity/user";
export const GET_CONTACT_COUNTS =
  BACKEND_URL + "statistics/entity/contact/status";
export const GET_METRICS_PERFORMANCE =
  BACKEND_URL + "statistics/metrics";
export const GET_TICKET_STATUSES =
  BACKEND_URL + "statistics/general/overall/status";
export const GET_PERFORMANCE_SCORES =
  BACKEND_URL + "statistics/general/overall/scores";
export const GET_CONTACT_AUTOCOMPLETE =
  BACKEND_URL + "statistics/entity/contact";
export const GET_FEEDBACK = BACKEND_URL + "statistics/feedback";
export const SEARCH_CONTACT_AUTOCOMPLETE =
  BACKEND_URL + "search/autocomplete/contact";
export const SEARCH_USER_AUTOCOMPLETE =
  BACKEND_URL + "search/autocomplete/user";
export const GET_TICKET_CHART_DATA =
  BACKEND_URL + "statistics/tickets/chart";
export const GET_CHANNEL_STATUSES =
  BACKEND_URL + "statistics/channel/status";
export const GET_ENTITY_TICKETS = BACKEND_URL + "statistics/tickets";
export const GET_CHANNEL_CHANNEL_ACCOUNTS =
  BACKEND_URL + "statistics/channelaccounts";
export const GET_PIE_CHART_DATA = BACKEND_URL + "charts/pie";
export const GET_BAR_CHART_DATA = BACKEND_URL + "charts/bar";
export const GET_GENERAL_STATUSES =
  BACKEND_URL + "statistics/general/statuses";
export const GET_TOP_ENTITIES =
  BACKEND_URL + "statistics/general/status/chart";
export const GET_ENTITY_SCORES =
  BACKEND_URL + "statistics/general/channel/scores/chart";
export const GET_ENTITY_TIMES =
  BACKEND_URL + "statistics/general/channel/times/chart";
export const GET_QUEUE_INFO =
  BACKEND_URL + "statistics/entity/queue/";
export const GET_QUEUE_COUNTS =
  BACKEND_URL + "statistics/entity/queue/status";
export const GET_QUEUE_SLA_SCORE =
  BACKEND_URL + "statistics/entity/queue/sla";
export const GET_API_TOKENS = BACKEND_URL + "apis/tokens";
export const GET_API_TYPES = BACKEND_URL + "api/types";
export const GENERATE_API_TOKEN = BACKEND_URL + "oauth/token";
export const SAVE_API_TOKEN = BACKEND_URL + "apis/tokens/store";
export const DELETE_API_TOKEN = BACKEND_URL + "apis/tokens/delete/";
export const SEND_SUPPORT_EMAIL = BACKEND_URL + "support/email/send";
export const SEND_SALES_EMAIL = BACKEND_URL + "sales/email/send";
export const GET_BILLING_INDUSTRIES =
  BACKEND_URL + "billing/industries";
export const CHECK_DATE_PERIOD = BACKEND_URL + "dates/verify";
export const CHECK_BILLING_INFO =
  BACKEND_URL + "payments/check/billing";
export const GET_FILE_TYPES = BACKEND_URL + "types/file";
