import * as routes from "../data/links/routes";
import { useAuth } from "../context/AuthContext";
import { Navigate, Outlet, useResolvedPath } from "react-router-dom";

const user_allowed_routes = [
  "/dashboard",
  "/conversations",
  "/profile",
  "/support",
  "/transcripts",
];

const UserRoute = () => {
  let { user } = useAuth();
  const { pathname } = useResolvedPath();

  return user.level === 0 &&
    user_allowed_routes.indexOf(pathname) === -1 ? (
    <Navigate to={routes.CONVERSATIONS} />
  ) : (
    <Outlet />
  );
};

export default UserRoute;
