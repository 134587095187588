import axios from "axios";
import { useState } from "react";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Stack from "@mui/material/Stack";
import Popper from "@mui/material/Popper";
import { NavLink } from "react-router-dom";
import Divider from "@mui/material/Divider";
import ProfileLogo from "../Logos/ProfileLogo";
import * as routes from "../../data/links/routes";
import { useAuth } from "../../context/AuthContext";
import PersonIcon from "@mui/icons-material/Person";
import { useSignalEffect } from "@preact/signals-react";
import * as endpoints from "../../data/links/endpoints";
import { profileImage } from "../../context/AuthContext";
import { systemErrors } from "../../data/helpers/objects";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { user_online_statuses } from "../../data/helpers/arrays";
import {
  CustomChip,
  CustomTooltip,
  StyledText,
  ProfileModalIcon,
  StyledCard,
  VerticalCenteredRow,
  ProfileModalRow,
} from "../../data/style/style";

const ProfileModal = (props) => {
  let { size, active, setActive, anchor, handleOpen, showBadge } =
    props;
  let { logoutUser, user, setUser } = useAuth();

  const [image, setImage] = useState(false);

  useSignalEffect(() => setImage(profileImage.value));

  let handleOnlineStatusChange = (status) => {
    var status_mode = 0;

    if (status.id !== 2) {
      status_mode = 1;
    }

    var raw = {
      online_status: status.id,
      mode: status_mode,
    };

    axios
      .post(endpoints.UPDATE_ONLINE_STATUS, raw)
      .then(() => {
        setUser({
          ...user,
          online_status: status.id,
          hasOnlineStatus: status.name,
          online_status_mode: status_mode,
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          showBadge(3, systemErrors.unauthorized);
          return;
        }

        if (error.response.status >= 500) {
          showBadge(3, systemErrors.system_error);
          return;
        }
      });
  };

  const items = [
    {
      title: "Profile",
      icon: <ProfileModalIcon component={PersonIcon} />,
      endpoint: "/" + routes.PROFILE,
    },
    {
      icon: (
        <VerticalCenteredRow
          gap={1}
          pl={0.5}
          justifyContent={"center"}
          width={"100%"}
        >
          <CustomTooltip
            placement="left"
            title={
              <Stack direction={"row"} gap={1}>
                {user_online_statuses
                  .filter((item) => item.id !== user.online_status)
                  .map((item, index) => (
                    <CustomChip
                      onClick={() => handleOnlineStatusChange(item)}
                      key={index}
                      sx={{
                        transition: "all .2s ease-in-out",
                        "&: hover": { transform: "scale(1.1)" },
                      }}
                      label={item.name}
                      status={item.id}
                    />
                  ))}
              </Stack>
            }
          >
            <CustomChip
              label={user.hasOnlineStatus}
              status={user.online_status}
            />
          </CustomTooltip>
        </VerticalCenteredRow>
      ),
    },
    {
      title: "Logout",
      icon: <ProfileModalIcon component={ExitToAppIcon} />,
      function: logoutUser,
    },
  ];

  return (
    <ClickAwayListener onClickAway={() => setActive(false)}>
      <Box>
        <ProfileLogo
          image={image}
          size={size}
          func={handleOpen}
          point={true}
        />
        <Popper
          open={active}
          anchorEl={anchor}
          placement={"bottom-end"}
          transition
          key={1}
          sx={{ zIndex: 10 }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <StyledCard elevation={0}>
                <Stack gap={1} p={1}>
                  {items.map((item, index) => (
                    <Box key={index}>
                      <NavLink
                        style={{ textDecoration: "none" }}
                        to={item?.endpoint}
                      >
                        <ProfileModalRow
                          index={index}
                          onClick={item?.function}
                          gap={1}
                        >
                          {" "}
                          {item.icon}
                          <StyledText>{item?.title}</StyledText>
                        </ProfileModalRow>
                      </NavLink>
                      <Divider />
                    </Box>
                  ))}
                </Stack>
              </StyledCard>
            </Fade>
          )}
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};
export default ProfileModal;
